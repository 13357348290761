<template>
  <div class="bankCard-wrap">
    <div class="content">
      <div class="label">银行卡号</div>
      <van-field v-model="bankCardNo" placeholder="请输入银行卡号"></van-field>
      <div class="label">
        预留手机号
        <van-icon name="warning-o" />
      </div>
      <van-field
        v-model="userTel"
        maxlength="11"
        oninput="value= value.replace(/[^\d]/g, '')"
        placeholder="请输入手机号"
      >
        <template #button>
          <div class="send-code" v-if="!isSend" @click="sendCode">
            获取验证码
          </div>
          <div class="send-code2" v-else>重新发送（{{ num }}）</div>
        </template>
      </van-field>
      <div class="label">短信验证码</div>
      <van-field
        v-model="verifyCode"
        oninput="value= value.replace(/[^\d]/g, '')"
        placeholder="请输入验证码"
        maxlength="6"
      />
    </div>
    <div class="footer" v-if="!isPopup">
      <template v-if="canClick">
        <div class="btn" @click="fourElements">确定</div>
      </template>
      <template v-else>
        <div class="btn btn2">确定</div>
      </template>
      <div class="des">
        不记得卡号/手机号？
        <span @click="goHome">尝试其他认证方式</span>
      </div>
    </div>
  </div>
</template>
<script>
  import Api from '@/api'
  import ApiSign from '@/api/addSign'
  import { Dialog, Toast } from 'vant'

  export default {
    props: ['isPopup'],
    data() {
      return {
        name: '',
        verifyCode: '',
        bankCardNo: '',
        userTel: '',
        isSend: false,
        num: 60,

        sendCodeLoading: false,
        loading: false,
      }
    },
    computed: {
      canClick() {
        if (this.userTel && this.bankCardNo && this.verifyCode) return true
        return false
      },
    },
    methods: {
      fourElements() {
        if (this.loading) return
        const { creditMsg } = this.$store.state
        if (!this.$checkPhone(this.userTel)) {
          Toast('请输入正确的手机号')
          return
        }
        this.loading = true
        Api.fourElements({
          verifyCode: this.verifyCode,
          bankCardNo: this.bankCardNo,
          userTel: this.userTel,
          ...creditMsg,
        })
          .then(res => {
            // const url = this.$store.state.signUrl
            // if (url === '/addSignConfirm') {
            //   this.addSign()
            //   return
            // }
            // if (url.includes('signdetail')) {
            //   this.$router.replace(url)
            //   return
            // }
            this.$router.push('/authenticationSuccess')
          })
          .catch(() => {
            this.$router.push({
              path: '/authenticationError',
              query: {
                type: 'card',
              },
            })
          })
          .finally(() => {
            this.loading = false
          })
      },
      sendCode() {
        if (this.sendCodeLoading) return
        if (!this.$checkPhone(this.userTel)) {
          Toast('请输入正确的手机号')
          return
        }
        this.sendCodeLoading = true
        Api.getTelCode({
          telephone: this.userTel,
          type: 2,
        })
          .then(res => {
            this.setCode()
          })
          .catch(err => {
            Toast(err)
          })
          .finally(() => {
            this.sendCodeLoading = false
          })
      },
      setCode() {
        this.isSend = true
        this.timer = setInterval(() => {
          if (!this.num) {
            clearInterval(this.timer)
            this.num = 60
            this.isSend = false
            return
          }
          this.num -= 1
        }, 1000)
      },
      goHome() {
        this.$router.push('/auth')
      },
      async addSign() {
        this.toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner',
        })
        ApiSign.joinSign({
          partnerId: this.$store.state.sign.partnerId,
          comId: null,
        })
          .then(async res => {
            await this.$store.dispatch('fetchUserInfo')
            const { msg, status, data } = res
            if (status === 200) {
              const { businessStatus } = data
              // 0 草稿 1未完成 2 已完成 3已作废
              if (businessStatus === 3) {
                this.$router.push({
                  path: '/signComplete',
                })
                return
              }
              this.$router.push({
                path: '/signdetail',
                query: {
                  businessId: data.businessId,
                },
              })
              return
            }
            Dialog.alert({
              title: '提示',
              message: msg,
            }).then(() => {})
          })
          .finally(() => {
            this.toast.clear()
          })
      },
    },
  }
</script>
<style lang="less" scoped>
  .bankCard-wrap {
    width: 100%;
    .header {
      width: 375px;
      height: 40px;
      background: rgba(248, 249, 251, 1);
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      .left {
        height: 16px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(109, 119, 144, 1);
        line-height: 16px;
      }
      .right {
        display: flex;
        align-items: center;
        img {
          width: 11px;
          height: 14px;
          margin-right: 8px;
        }
        height: 16px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(22, 187, 131, 1);
        line-height: 16px;
      }
    }
    .content {
      padding: 0 16px;
      .label {
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(17, 26, 52, 1);
        line-height: 22px;
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      /deep/.van-cell {
        padding: 13px 0;
        height: 48px;
        margin-bottom: 24px;
      }
      .send-code {
        height: 18px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(22, 118, 255, 1);
        line-height: 18px;
      }
      .send-code2 {
        height: 18px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(109, 119, 144, 1);
        line-height: 18px;
      }
    }
    .footer {
      position: fixed;
      bottom: @safe-area;
      left: 0;
      width: 375px;
      height: 102px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0 -6px 10px 0 rgba(230, 234, 237, 0.3);
      padding-top: 10px;
      .btn {
        width: 327px;
        height: 44px;
        background: rgba(22, 118, 255, 1);
        box-shadow: 0 6px 20px 0 rgba(78, 128, 245, 0.16);
        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: 44px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 16px;
      }
      .btn2 {
        background: rgba(22, 118, 255, 0.2);
      }
      .des {
        height: 16px;
        font-size: 12px;
        font-weight: 400;
        color: rgba(109, 119, 144, 1);
        line-height: 16px;
        text-align: center;
        span {
          color: rgba(22, 118, 255, 1);
        }
      }
    }
  }
</style>
